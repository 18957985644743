import { ReactSVG } from "react-svg";
import {useQuestionContext} from "../context/QuestionsContext";

export const ImageComponent = ({src, defaultSrc, style, isVisible, link}) => {
    console.log('ImageComponent', {src, defaultSrc, link});

    if((!src && !defaultSrc)) return null;

    if (typeof isVisible !== 'undefined' && isVisible !== true) return null;

    let _src = src ? src : defaultSrc;

    if(_src.indexOf(".svg")>-1) {
        return (
            <ReactSVG src={_src}/>
        );
    }
    if(link && link.length > 1) {
        return (
            <a href={link}><img src={_src} style={ style || {}}/></a>
        )
    }

    return (
        <img src={_src} style={ style || {}}/>
    )
}

function convertToFontClass(iconName) {
    const stylePrefix = 'fas';
    /** Added changes to support new array **/
    if (typeof iconName === 'string') {
        const iconNameKebab = iconName.replace(/([a-z])([A-Z])/g, '$1-$2').toLowerCase();
        return `${stylePrefix} ${iconNameKebab}`;
    } else if (Array.isArray(iconName) && iconName.length > 1) {
        const iconNameKebab = iconName[1].replace(/([a-z])([A-Z])/g, '$1-$2').toLowerCase();
        return `${iconName[0]} ${iconNameKebab}`;
    }
}

export const GetIconOrImage = ({iconObject, height, width, fontSize}) => {
    if(iconObject.type === 'icon') {
        const {color, backgroundColor,value}  = iconObject;
        const icon = convertToFontClass(value);
        return (
            <div style={{
                height: height || 'auto',
                width: width || 'auto',
                backgroundColor: backgroundColor || 'transparent',
                borderRadius: '50%',
                alignItems: 'center',
                justifyContent: 'center',
                display: 'flex',
                marginLeft: 5,
            }}>
                <i style={{fontSize: fontSize, color}} className={`${icon}`}></i>
            </div>
        )
    }
    return <img style={{height: height || 'auto', width: width || 'auto', marginRight: 5}} src={iconObject.value} alt={""}/>
}


export const GetIcon = (option) => {
    const {customizations} = useQuestionContext();

    if(option?.iconObject?.type === 'icon') {
        // {"via": "icons", "type": "icon", "color": "#002B80", "value": "faFaceSmile", "backgroundColor": "#E2CACA"}
        const {color, backgroundColor,value}  = option.iconObject;
        console.log({color, backgroundColor,value});
        const icon = convertToFontClass(value);
        console.log('icon', icon);
        return (
            <div style={{
                height: 48,
                width: 48,
                backgroundColor: backgroundColor || 'transparent',
                borderRadius: '50%',
                alignItems: 'center',
                justifyContent: 'center',
                display: 'flex',
                marginLeft: 5,
            }}>
                <i style={{fontSize: 32, color}} className={`${icon}`}></i>
            </div>
        )
    }

    if(option.icon && (option.icon.indexOf('icon:')>-1)) {
        let icon = `${option.icon}`;
        icon = icon.split(',');
        console.log('icon', icon);
        let color = icon.length > 1 ? icon[1] : customizations['--button-text-color'] || 'black';
        let backgroundColor = icon.length > 2 ? icon[2] : customizations['--button-color'] || 'transparent';

        return (
            <div style={{
                height: 48,
                width: 48,
                backgroundColor: backgroundColor || 'transparent',
                borderRadius: '50%',
                alignItems: 'center',
                justifyContent: 'center',
                display: 'flex',
                marginLeft: 5,
            }}>
                <i style={{fontSize: 24, color: color}} className={icon[0].replace('icon:','')}></i>
            </div>
        )
    }
    if(option.icon && (option.icon.indexOf('http')>-1 || option.icon.indexOf('.png')>-1 )) {
        return <img style={{height: 24, width: 24, marginRight: 5}} src={option.icon} alt={""}/>
    }
    return null;
}

export const GetCategoryIcon = (option, dimensions) => {
    const {customizations} = useQuestionContext();

    if(option.iconObject) {
        const width = Math.min(Math.min(500, dimensions.width) *.8 / 3, 100);
        return (
            <div>
                <GetIconOrImage
                    iconObject={option.iconObject}
                    width={width}
                    height={width}
                />
                <p>{option.text}</p>
            </div>
        )
    }
    if(option.icon && (option.icon.indexOf('icon:')>-1)) {
        let icon = `${option.icon}`;
        icon = icon.split(',');
        let color1 = icon.length > 1 ? icon[1] : customizations['--button-text-color'] || 'black';
        let color2 = icon.length > 2 ? icon[2] : customizations['--button-color'] || 'transparent';
        console.log('icon', icon);
        return (
            <div>
                <div className="category-icon" style={{backgroundColor: color2}}>
                    <i style={{fontSize: 50, color: color1}} className={icon[0].replace('icon:','')}></i>
                </div>
                <p>{option.text}</p>
            </div>
        )
    }
    if(option.icon && (option.icon.indexOf('http')>-1 || option.icon.indexOf('.png')>-1 )) {
        return(
            <div>
                <div className="category-icon">
                    <img style={{height: 50, width: 50, marginRight: 5}} src={option.icon} alt={""}/>
                </div>
                <p>{option.text}</p>
            </div>
        );
    }
    return null;
}
