import {ImageComponent} from "../Image";
import {useQuestionContext} from "../../context/QuestionsContext";
import parse from 'html-react-parser';
import {formatStyle} from "../StyleUtil";


export const GoodByeDefault = props => {
    const {getLogo, customizations} = useQuestionContext();

    const {question} = props;

    const icon = question.iconObject?.value || question.icon;
    console.log('GoodByeDefault', icon);
    return (
        <>
            <div className="goodbye questionContainer">
                <div style={{display: "flex", flexDirection: 'column', alignContent: 'space-evenly', justifyContent: 'center'}}>
                    <ImageComponent src={getLogo()} style={{
                        maxWidth:500,
                        maxHeight:50,
                        width: 'auto',
                        height: 'auto',
                        marginLeft: 'auto',
                        marginRight: 'auto',
                        marginTop: 10
                    }} link={customizations?.logoUrl} isVisible={props.question.showLogo}/>
                    <h1 style={formatStyle(props?.question?.textStyle)}>{props.question.text}</h1>
                    <div className="">
                        <ImageComponent src={icon} default={null} style={{maxHeight : 200}}/>
                    </div>
                    <p style={formatStyle(props?.question?.placeholderStyle)}>{parse(props.question?.placeholder || '')}</p>
                    <div className="button-float clickable"
                         style={props.question?.isBurstAgain === true ? {} : {display: 'none'}}
                         onClick={() => window.location.reload()}
                    >
                        <p>{props.question?.buttonText || 'Burst Again'}</p>
                    </div>
                    <div className="powered-by-float clickable" onClick={()=>window.location.href="https://letsburst.com/"}>
                        <p>Powered By</p>
                        <img src={'/letsburstlogo.png'} alt="burst_logo"/>
                    </div>
                </div>
            </div>
        </>
    );
}
