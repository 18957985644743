import {useState} from "react";
import {useQuestionContext} from "../../context/QuestionsContext";
import {GetCategoryIcon, GetIcon} from "../Image";
import {formatStyle} from "../StyleUtil";

export const QuestionCategories = props => {
    const [response, setResponse] = useState(0);
    const {next} = useQuestionContext();
    const [warning, setWarning] = useState(null);
    const [dimensions, setDimensions] = useState({
        width: window.innerWidth,
        height: window.innerHeight,
    });

    console.log('QuestionRadio', props.question);
    return (
        <>
            <div id="question-categories" className="questionContainer">
                <h1 style={formatStyle(props?.question?.textStyle)}>{props.question.text}</h1>
                <div className="row">
                {props.question.options.map(option => {
                    return (
                        <div key={`options_${Math.random()}`} className="col clickable" onClick={()=>{
                            next(props.question, [option.id])
                        }}>
                            <div className="category">
                                {GetCategoryIcon(option, dimensions)}
                            </div>
                        </div>
                    )
                })}
                </div>
                {warning}
                <div className="powered-by-float clickable" onClick={()=>window.location.href="https://letsburst.com/"}>
                    <p>Powered By</p>
                    <img src={'/letsburstlogo.png'} alt="burst_logo"/>
                </div>
            </div>
            <div className="footer">

            </div>
        </>

    )
}
