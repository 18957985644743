import {useState} from "react";
import {useQuestionContext} from "../../context/QuestionsContext";
import {GetIcon, GetIconOrImage} from "../Image";
import {formatStyle} from "../StyleUtil";

const buildGroupResponseIndex = (options=>{
    let a = {};
    options.forEach(q=>{
        if(q.code) {
            if(!a[q.code]) a[q.code]=0;
        }
    })
    return a;
})

export const Demographics = props => {
    const [responses, setResponses] = useState(buildGroupResponseIndex(props.question.options));
    const {next} = useQuestionContext();

    console.log('QuestionSelect', responses);
    const groupings = [];
    return (
        <>
            <div className="questionContainer">
                <h1 style={formatStyle(props?.question?.textStyle)}>{props.question.text}</h1>
                {
                    Object.keys(responses).map(code=>{
                        let options = props.question.options.filter(a=>a.code===code).map(option=>{
                            if(option.iconObject !==null) {
                                return (
                                    <div className={`radio-wrapper ${responses[code] === option.id ? 'is-selected' : ''}`}
                                         onClick={()=>{
                                             console.log('onChange', code, option.id)
                                             setResponses({...responses, [code]: option.id})
                                         }}
                                    >
                                        <GetIconOrImage iconObject={option.iconObject} width={50} height={50} labelSize={18} fontSize={40}/>
                                        <p>{option.text}</p>
                                    </div>
                                )
                            }
                            return (
                                <div className={`radio-wrapper ${responses[code] === option.id ? 'is-selected' : ''}`}
                                     onClick={()=>{
                                         console.log('onChange', code, option.id)
                                         setResponses({...responses, [code]: option.id})
                                     }}
                                >
                                    <p>{option.text}</p>
                                </div>
                            )
                        });
                        return (
                            <div className="demographicsOptionsBox">
                                <h2>{code}</h2>
                                <div key={`options_${Math.random()}`} className="demographicsOptionContainer clickable">
                                    {options}
                                </div>
                            </div>
                        )
                    })
                }
                <div className="button-float clickable"
                     onClick={() => {
                         const answers = [];
                         Object.keys(responses).forEach(key=>{
                             if(responses[key]) answers.push(responses[key]);
                         })
                         next(props.question, answers);
                     }}>
                    <p>{props.question?.buttonText || 'next'}</p>
                </div>
                <div className="powered-by-float clickable" onClick={()=>window.location.href="https://letsburst.com/"}>
                    <p>Powered By</p>
                    <img src={'/letsburstlogo.png'} alt="burst_logo"/>
                </div>
            </div>
        </>
    );
}
