import {useState} from "react";
import {useQuestionContext} from "../../context/QuestionsContext";
import {ImageComponent} from "../Image";
import {formatStyle} from "../StyleUtil";

const getStyle = (word, checked, wordCloudStyle) => {
    console.log('getStyle', wordCloudStyle);
    let length = 0;
    word.split(' ').forEach(w => length = Math.max(length, w.length));
    if (word.split(' ').length > 3) length += 8
    let width = 50 + ((length - 4) * (4));
    if (checked) return {height: width, width, backgroundColor: wordCloudStyle?.selectedBackGroundColor || '#FFC629', color: '#000000'}
    return {height: width, width, backgroundColor: wordCloudStyle?.backGroundColor || null, color: wordCloudStyle?.color || null}
    return {height: width, width}
}

function getRandomInt(max) {
    return Math.floor(Math.random() * max);
}

export const getWords = (length, words, previouslyUsed = []) => {
    console.log('getWords', {words: words.length, used: previouslyUsed.length})
    let unused = words.filter(a => !previouslyUsed.includes(a))
    let newWords = [];
    for (let i = 0; i < length && i < unused.length; i++) {
        newWords.push(unused[getRandomInt(unused.length - 1)]);
        unused = unused.filter(a => !newWords.includes(a))
    }
    return newWords;
}

const buildResponseIndex = (options => {
    let a = {};
    options.forEach(o => a[o.id] = false);
    return a;
})

export const WordCloud = props => {
    const [responses, setResponses] = useState(buildResponseIndex(props.question.options));
    const [words, setWords] = useState(getWords(9, props.question.options))
    const {next, getLogo, customizations, getQuestionText} = useQuestionContext();
    const [warning, setWarning] = useState(null);

    return (
        <>
            <div id="wordcloud" className="questionContainer">
                <ImageComponent
                    src={getLogo()}
                    link={customizations?.logoUrl}
                    style={{height: 50, marginTop: 20}}
                    isVisible={props.question.showLogo}
                />
                <h1 style={formatStyle(props?.question?.textStyle)}>{getQuestionText(props.question.text)}</h1>
                <p className="info">Please select all that apply</p>
                <div className="flex-container">
                    {words.map(option => {
                        const color = responses[option.id]
                            ? props.question?.wordCloudStyle?.selectedColor ||'#000000'
                            : props.question?.wordCloudStyle?.color ||'#000000';
                        return (
                            <div style={getStyle(option.text, responses[option.id]||false, props.question?.wordCloudStyle || null)}
                                 className="clickable"
                                 onClick={() => {
                                     setResponses({...responses, [option.id]: !responses[option.id]})
                                 }}
                            >
                                <span style={{color}}>{option.text}</span>
                            </div>)
                    })}
                </div>
                <button className="alpaca-link clickable" onClick={() => {
                    let _usedWords = props.question.options.filter(a => {
                        console.log('used_words', a.id, responses[a.id])
                        return responses[a.id]
                    })
                    setWords(getWords(9, props.question.options, _usedWords))
                }}>{props.question.placeholder || 'This is fun! More words please.'}</button>
                {warning}
                <div className="footer">
                    <div className="button-fixed clickable"
                         onClick={() => {
                             let response = [];
                             Object.entries(responses).forEach(entry=>{
                                 const [key, value] = entry;
                                 if(value) response.push(key)
                             });
                             if(!props.question.isRequired || response.length > 0) {
                                 next(props.question, response)}
                             else {
                                 setWarning(<div className="info" style={{color: 'red'}}>Please select at least one option</div> )
                             }
                         }}>
                        <p>Submit</p>
                    </div>
                    <div className="powered-by-float clickable" onClick={()=>window.location.href="https://letsburst.com/"}>
                        <p>Powered By</p>
                        <img src={'/letsburstlogo.png'} alt="burst_logo"/>
                    </div>
                </div>
            </div>
        </>
    )
}


