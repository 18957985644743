import {useState} from "react";
import {useQuestionContext} from "../../context/QuestionsContext";
import {GetIcon, ImageComponent} from "../Image";
import parse from "html-react-parser";
import {formatStyle} from "../StyleUtil";

export const QuestionSocialReviews = props => {
    const {next, getLogo, customizations} = useQuestionContext();

    return (
        <>
            <div className="questionContainer">
                <ImageComponent
                    src={getLogo()}
                    link={customizations?.logoUrl}
                    style={{height: 50, marginTop: 20}}
                    isVisible={props.question.showLogo}
                />
                <h1 style={formatStyle(props?.question?.textStyle)}>{props.question.text}</h1>
                <p style={formatStyle(props?.question?.placeholderStyle)}>{parse(props.question?.placeholder || '')}</p>

                <div style={{display: "flex", flexDirection: "row", justifyContent: "space-around", alignContent: "center"}}>
                    <a href="https://search.google.com/local/writereview">
                        <img src="/reviews/google.png" style={{height: 50, width: 50}}/>
                    </a>
                </div>


                <div className="button-float clickable" onClick={() => {
                    next(props.question, )}
                }>
                <p>{props.question?.buttonText || 'Next'}</p>
                </div>
                <div className="powered-by-float clickable" onClick={()=>window.location.href="https://letsburst.com/"}>
                    <p>Powered By</p>
                    <img src={'/letsburstlogo.png'} alt="burst_logo"/>
                </div>
            </div>
            <div className="footer">

            </div>
        </>

    )
}
