import {Header} from "./components/header";
import {useQuestionContext} from "./context/QuestionsContext";
import {QuestionText} from "./components/questions/QuestionText";
import {Welcome} from "./components/welcome/Welcome";
import {GoodBye} from "./components/welcome/GoodBye";
import {WordCloud} from "./components/questions/WordCloud";
import {QuestionCheckbox} from "./components/questions/QuestionCheckbox";
import {QuestionRadio} from "./components/questions/QuestionRadio";
import {QuestionSelect} from "./components/questions/QuestionSelect";
import {WelcomeDefault} from "./components/welcome/WelcomeDefault";
import {GoodByeDefault} from "./components/welcome/GoodByeDefault";
import {QuestionRanking} from "./components/questions/QuestionRankings";
import {PageLoader} from "./components/PageLoader";
import {QuestionCategories} from "./components/questions/QuestionCategories";
import {QuestionContactForm} from "./components/questions/QuestionContactForm";
import {Demographics} from "./components/questions/Demographics";
import {QuestionRankingDefault} from "./components/questions/QuestionRankingsDefault";
import {QuestionRadioImage} from "./components/questions/QuestionRadioImage";
import {QuestionSocialReviews} from "./components/questions/QuestionSocialReviews";

function App() {
    const {activeQuestion} = useQuestionContext();

    console.log('App:activeQuestion', activeQuestion);

    if(activeQuestion.type==='loading') {
        return (
            <PageLoader/>
        )
    }

    if(activeQuestion.type==='welcome') {
        return (
            <div className="App">
                <div className="container">
                    <Welcome/>
                </div>
            </div>

        )
    }
    if(activeQuestion.type==='welcomeDefault') {
        return (
            <div className="App">
                <div className="container">
                    <WelcomeDefault question={activeQuestion}/>
                </div>
            </div>

        )
    }
    else if(activeQuestion.type==='welcome:alpaca') {
        return (
            <div className="App">
                <div className="container">
                    <Welcome question={activeQuestion}/>
                </div>
            </div>

        )
    }

    const question = activeQuestion.type === 'text'
        ? <QuestionText question={activeQuestion} key={`question_${activeQuestion.id}`}/>
        : activeQuestion.type === 'wordcloud'
        ? <WordCloud question={activeQuestion} key={`question_${activeQuestion.id}`}/>
        : activeQuestion.type === 'goodbye'
        ? <GoodBye question={activeQuestion}/>
        : activeQuestion.type === 'checkbox'
        ? <QuestionCheckbox question={activeQuestion} key={`question_${activeQuestion.id}`}/>
        : activeQuestion.type === 'select'
        ? <QuestionSelect question={activeQuestion} key={`question_${activeQuestion.id}`}/>
        : activeQuestion.type === 'ranking'
        ? <QuestionRanking question={activeQuestion} key={`question_${activeQuestion.id}`}/>
        : activeQuestion.type === 'ranking:default'
        ? <QuestionRankingDefault question={activeQuestion} key={`question_${activeQuestion.id}`}/>
        : activeQuestion.type === 'goodbyeDefault'
        ? <GoodByeDefault question={activeQuestion} key={`question_${activeQuestion.id}`}/>
        : activeQuestion.type === 'categories'
        ? <QuestionCategories question={activeQuestion} key={`question_${activeQuestion.id}`}/>
        : activeQuestion.type === 'contactform'
        ? <QuestionContactForm question={activeQuestion} key={`question_${activeQuestion.id}`}/>
        : activeQuestion.type === 'demographics'
        ? <Demographics question={activeQuestion} key={`question_${activeQuestion.id}`}/>
        : activeQuestion.type === 'socialreviews'
        ? <QuestionSocialReviews question={activeQuestion} key={`question_${activeQuestion.id}`}/>
        : activeQuestion.type === 'radio:image'
        ? <QuestionRadioImage question={activeQuestion} key={`question_${activeQuestion.id}`}/>
        : <QuestionRadio question={activeQuestion} key={`question_${activeQuestion.id}`}/>



    const header = activeQuestion.type.indexOf('goodbye') > -1
    ? <Header key={`progress_${activeQuestion.id}`} questionNumber={activeQuestion.questionNumber} progressMessage={'1,000% Complete!'}/>
    : <Header key={`progress_${activeQuestion.id}`} questionNumber={activeQuestion.questionNumber}/>


    return (
        <div className="App">
            <div className="container">
                <div className="container-decoration-a"/>
                <div className="container-decoration-b"/>
                {header}
                {question}
            </div>
        </div>
    );
}

export default App;
