import { useState } from "react";
import { useQuestionContext } from "../../context/QuestionsContext";
import { formatStyle } from "../StyleUtil";
import { GetIcon, ImageComponent } from "../Image";

const sortNames = (a, b) => {
    const nameA = a.text.toUpperCase(); // ignore upper and lowercase
    const nameB = b.text.toUpperCase(); // ignore upper and lowercase
    if (nameA < nameB) {
        return -1;
    }
    if (nameA > nameB) {
        return 1;
    }
    return 0;
}

const getUnique = (options) => {
    let data = [];
    options.forEach(a => {
        let i = data.filter(b => b.text.replace(' School', '') === a.text.replace(' School', ''))[0] || false;
        if (!i) data.push(a);
    });
    return data;
}

export const QuestionSelect = props => {
    const [response, setResponse] = useState(0);
    const { next, getLogo } = useQuestionContext();
    const [warning, setWarning] = useState(null);


    console.log('QuestionRadio', props.question);
    return (
        <>
            <div className="questionContainer">
                <ImageComponent src={getLogo()} style={{ height: 50, marginTop: 20 }} isVisible={props.question.showLogo} />
                <h1 style={formatStyle(props?.question?.textStyle)}>{props.question.text}</h1>
                <select value={response} onChange={(event) => setResponse(event.target.value)}>
                    <option>Please Select One</option>
                    {getUnique(props.question.options)
                        .sort(sortNames)
                        .map(option => {
                            return (
                                <option value={option.id}>{option.text}</option>
                            )
                        })}
                </select>
                {warning}
                <div className="button-float clickable" style={{ marginTop: 30 }} onClick={() => {
                    if (!props.question.isRequired || response > 0) {
                        next(props.question, [response])
                    }
                    else {
                        setWarning(<div className="info" style={{ color: 'red' }}>Please select one option</div>)
                    }
                }}>
                    <p>Next</p>
                </div>
                <div className="powered-by clickable" onClick={() => window.location.href = "https://letsburst.com/"}>
                    <p>Powered By</p>
                    <img src={'/letsburstlogo.png'} alt="burst_logo" />
                </div>

            </div>

        </>

    )
}
