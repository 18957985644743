import {useEffect, useState} from "react";
import {useQuestionContext} from "../../context/QuestionsContext";
import {Header} from "../header";
import {GetIconOrImage, ImageComponent} from "../Image";
import {formatStyle} from "../StyleUtil";
import parse from "html-react-parser";

const getIcon = (option) => {
    if(option.icon && (option.icon.indexOf('http')>-1 || option.icon.indexOf('.png')>-1 )) {
        return <img style={{height: 24, width: 24, marginRight: 5}} src={option.icon} alt={""}/>
    }
    return null;
}

export const QuestionRadioImage = props => {
    const [response, setResponse] = useState(0);
    const [value, setValue] = useState(0);
    const {next, getLogo, customizations} = useQuestionContext();
    const [warning, setWarning] = useState(null);
    const {comment, setComment} = useQuestionContext(null);
    const [dimensions, setDimensions] = useState({
        width: window.innerWidth,
        height: window.innerHeight,
    });

    useEffect(() => {
        const handleResize = () => {
            setDimensions({
                width: window.innerWidth,
                height: window.innerHeight,
            });
        };

        window.addEventListener('resize', handleResize);

        // Clean up the event listener when the component unmounts
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);
    console.log('QuestionRanking', props.question);
    console.log('QuestionRanking', customizations);

    return (
        <>
            <Header questionNumber={props.question.questionNumber}/>
            <div className="questionContainer">
                <ImageComponent
                    src={getLogo()}
                    link={customizations?.logoUrl}
                    style={{height: 50, marginTop: 20}}
                    isVisible={props.question.showLogo}
                />

                <div style={{marginTop: 50}}>
                    <h1 style={formatStyle(props?.question?.textStyle)}>{props.question.text}</h1>
                <div style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    marginLeft: '10%',
                    width: '80%'
                }}>
                    {props.question.options.map(option => {
                       const width = Math.min(Math.min(500, dimensions.width) *.8 / props.question.options.length, 100);
                       const fontSize = width * .7;
                       return (
                           <div className={'clickable'} style={{ justifyContent: "center", width: width}} onClick={()=>{
                               next(props.question, [option.id], null)
                           }}>
                               <GetIconOrImage iconObject={option.iconObject} width={width} height={width} labelSize={18} fontSize={fontSize}/>
                               <p style={{fontSize: 18}}>{option.text}</p>
                           </div>
                       )
                    })}
                </div>
                <div className="powered-by-float clickable" onClick={()=>window.location.href="https://letsburst.com/"}>
                    <p>Powered By</p>
                    <img src={'/letsburstlogo.png'} alt="burst_logo"/>
                </div>
                </div>
            </div>

        </>

    )
}
