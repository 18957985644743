import {useState} from "react";
import {useQuestionContext} from "../../context/QuestionsContext";
import {Header} from "../header";
import {GetIconOrImage, ImageComponent} from "../Image";
import {formatStyle} from "../StyleUtil";

const getIcon = (option) => {
    if(option.icon && (option.icon.indexOf('http')>-1 || option.icon.indexOf('.png')>-1 )) {
        return <img style={{height: 24, width: 24, marginRight: 5}} src={option.icon} alt={""}/>
    }
    return null;
}

export const QuestionRankingDefault = props => {
    const [response, setResponse] = useState(0);
    const [value, setValue] = useState(0);
    const {next, getLogo, customizations} = useQuestionContext();
    const [warning, setWarning] = useState(null);
    const {comment, setComment} = useQuestionContext(null);
    console.log('QuestionRanking', props.question);
    console.log('QuestionRanking', customizations);

    // const unSelectedIdx = props.question.options.findIndex(a=>a.sequence === 0);
    // const selectedIdx = props.question.options.findIndex(a=>a.sequence === 1);
    // const selectedIconObject = props.question.options[selectedIdx].iconObject;
    // const unselectedIconObject = props.question.options[unSelectedIdx].iconObject;

    return (
        <>
            <Header questionNumber={props.question.questionNumber}/>
            <div className="questionContainer">
                <ImageComponent
                    src={getLogo()}
                    link={customizations?.logoUrl}
                    style={{height: 50, marginTop: 20}}
                    isVisible={props.question.showLogo}
                />

                <div style={{marginTop: 50}}>
                <h1 style={formatStyle(props?.question?.textStyle)}>{props.question.text}</h1>
                <div style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-around',
                    marginLeft: '10%',
                    width: '80%'
                }}>
                    {props.question.options.map(option => {
                       // let iconObject = value >= option.value ? selectedIconObject : unselectedIconObject;
                       // console.log('iconObject', iconObject);
                       return (
                           <div className={'clickable'} style={{flex: 1}} onClick={()=>{
                               setValue(option.value)
                               setResponse(option.id);
                           }}>
                               <GetIconOrImage iconObject={option.iconObject} width={75} height={75} fontSize={50}/>
                               <p style={{fontSize: 12}}>{option.text}</p>
                           </div>
                       )
                    })}
                </div>
                {warning}
                <div className="button-float clickable" onClick={() => {
                    if(response > 0) {
                        next(props.question, [response], comment)}
                    else {
                        setWarning(<div className="info" style={{color: 'red'}}>Please select one option</div> )
                    }
                }}>
                    <p>{props.question?.buttonText || 'Next'}</p>
                </div>
                <div className="powered-by-float clickable" onClick={()=>window.location.href="https://letsburst.com/"}>
                    <p>Powered By</p>
                    <img src={'/letsburstlogo.png'} alt="burst_logo"/>
                </div>
                </div>
            </div>

        </>

    )
}
