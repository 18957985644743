import { createContext, useContext, useEffect, useState } from "react";
import { browserName, osName } from "react-device-detect";

import dayjs from "dayjs";

import axios from 'axios';

export const QuestionsContext = createContext({
    questions: [],
    questionNumber: 0,
    responses: {}
});

const BASE_URL =
    window.location.href.indexOf('localhost') > -1
        ? 'http://localhost:3001/'
        : window.location.href.indexOf('dev') > -1
            ? 'https://api-burst-api-dev-east.azurewebsites.net/'
            : window.location.href.indexOf('letsburst.com') > -1
                ? 'https://app-burst-prod-east.azurewebsites.net/'
                : 'https://api-burst-api-dev-east.azurewebsites.net/';
const defaultQuestions = [
    {
        id: 0,
        type: 'loading',
        name: 'first',
        isQuestion: false,
        responses: [],
    },
]

const defaultApiValues = {
    "idCustomer": 4,
    "idProduct": 690,
    "idCategory": 1054
}

let _SESSION = false;

const loadSession = async () => {
    if (_SESSION) return;
    _SESSION = true;
    let data = {
        "browser": browserName,
        "platform": osName,
        "url": window.location.host,
        urlHash: window.location.hash
    }
    return await PostData(`${BASE_URL}api-app/session`, data);
}

const updateCustomizations = (customizations) => {
    Object.keys(customizations).forEach(a => {
        console.log('setCustomizations', customizations[a], a);
        document.documentElement.style.setProperty(a, customizations[a]);
    })
}


export const QuestionContextProvider = (props) => {
    const [questions, setQuestions] = useState(defaultQuestions);
    const [authToken, setAuthToken] = useState(false);
    const [pageNumber, setPageNumber] = useState(0);
    const [responses, setResponses] = useState([]);
    const [comment, setComment] = useState("");
    const [history, setHistory] = useState([0])
    const [customizations, setCustomizations] = useState()


    useEffect(() => {
        console.log(window.location);
        loadSession().then((result) => {
            updateCustomizations(result.data.customizations);
            setCustomizations(result.data.customizations);
            console.log('questions', result.data.questions.map(a => a.sequence));
            setQuestions(result.data.questions);
            setAuthToken(result.data.token.authToken);
        })
    }, []);

    return (
        <QuestionsContext.Provider
            value={{
                questions,
                pageNumber,
                setPageNumber,
                responses,
                setResponses,
                history,
                setHistory,
                comment,
                setComment,
                authToken,
                customizations,
                setCustomizations: (data) => setCustomizations(prevState => {
                    return { ...prevState, ...data }
                })
            }}
        >
            {props.children}
        </QuestionsContext.Provider>
    );
}

const getRatings = (questions) => {
    let _ratings = {};
    questions.filter(q => q.isQuestion).forEach((q) => {
        q.options.forEach(r => _ratings[r.id] = r)
    }
    )
    return _ratings;
}

function parsePlaceholders(text) {
    let placeholders = [];
    let match;
    let placeholderRegex = /{([^}]+)}/g;

    while ((match = placeholderRegex.exec(text)) !== null) {
        placeholders.push(match[1]);
    }

    return placeholders;
}

function replaceVariables(text, customizations) {
    let placeholders = parsePlaceholders(text);
    if (!placeholders || placeholders.length == 0) return text;
    console.log('replace', text, placeholders, customizations);
    placeholders.forEach(v => {
        console.log('replace', `{${v}}`, 'with', customizations[v]);
        text = text.replace(`{${v}}`, customizations[v]);
    })
    return text;
}



export const useQuestionContext = () => {
    const {
        questions,
        pageNumber,
        setPageNumber,
        responses,
        setResponses,
        history,
        setHistory,
        comment,
        setComment,
        authToken,
        customizations,
        setCustomizations
    } = useContext(QuestionsContext);
    const [activeQuestion, setActiveQuestion] = useState(questions[0])
    const [isSaving, setIsSaving] = useState(false)

    useEffect(() => {
        setActiveQuestion(questions[pageNumber])
    }, [pageNumber, questions])

    useEffect(() => {
        console.log('replace: useState:customizations', customizations);
    }, [customizations]);

    useEffect(() => {
    }, [responses])

    useEffect(() => {
        console.log('next: history', history)
    }, [history])

    const getQuestionText = (text) => {
        return text.replace(/\{(\w+)\}/g, function (match, key) {
            return customizations[key] || '';
        });
    }

    const last = (question, response) => {
        setIsSaving(true);
        saveResponses().then(result => {
            setIsSaving(false);
            next(question, response);
        });
    }

    const getOptions = (ids) => {
        let options = [];
        if (!ids || ids.length === 0) return [];
        ids = ids.map(a => Number(a));
        questions.forEach(q => {
            q?.options.forEach(opt => {
                if (ids.includes(opt.id)) {
                    options.push(opt);
                }
            })
        })
        return options;
    }

    // Loops through all responses and matching forFor questionOption.ref to see if there's a match;
    const hasForkMatch = (currentResponses, forkOn) => {
        if (!forkOn || forkOn.length === 0) return false;
        let refs = currentResponses.map(a => a.ref);
        console.log(refs);
        let found = false;
        forkOn.forEach(a => {
            if (!found && refs.includes(a)) found = true;
        })
        return found;
    }

    const nextContact = (question, responses) => {
        let dataResponses = [];
        let dataContacts = [];
        Object.keys(responses).forEach(id => {
            if (responses[id] && responses[id].length > 1) {
                dataResponses.push(Number(id));
                dataContacts.push(responses[id])
            }
        })
        if (dataResponses.length) {
            PostData(`${BASE_URL}api-app/response`, {
                questionRef: question.ref,
                responses: dataResponses,
                contacts: dataContacts
            }, authToken);
        }
        next(question, [], null);
    }

    const next = (question, response, text) => {
        let next_page = Math.min(pageNumber + 1, questions.length - 1);
        console.log('response', response);
        if ((question?.type === 'text' || question?.type === 'ranking') && text && text.length > 0) {
            PostData(`${BASE_URL}api-app/response`, { questionRef: question.ref, text }, authToken);
        }
        if (response && response.length > 0) {
            PostData(`${BASE_URL}api-app/response`, {
                questionRef: question.ref, responses: response.map(a => Number(a))
            }, authToken);
        }

        // save all questionOption into responses
        let options = getOptions(response);
        let _responses = responses || [];
        options.forEach(a => _responses.push(a));
        setResponses(responses);

        console.log('question', question?.ref, question?.text);
        if (options.length > 0) {
            console.log('replace:variable', options[0].text);
            if (question) {
                setCustomizations({ ...customizations, [`q${question.questionNumber}`]: options[0].text })
            }
            if (question?.ref === '4565lu44uqB') {
                console.log('replace: peek', { ...customizations, selection: options[0].text });
                setCustomizations({ ...customizations, selection: options[0].text })
            }
        }

        // find next question
        let found_question = null;
        while (found_question === null) {
            console.log('next', next_page, questions[next_page].text, { isFork: questions[next_page].isFork, forkOn: questions[next_page].forkOn })
            if (questions[next_page]?.isFork) {
                if (hasForkMatch(_responses, questions[next_page].forkOn)) {
                    found_question = questions[next_page];
                } else {
                    next_page++;
                }
            } else {
                found_question = questions[next_page];
            }
        }
        setComment('');
        setHistory([...history, next_page])
        setPageNumber(next_page);
    }

    const back = () => {
        let _history = history;
        _history.pop();
        setHistory(history);
        setPageNumber(Math.max(_history[_history.length - 1], 0));
    }

    const saveResponses = async () => {
        await postResponses(questions, responses, comment);
    }

    const getLogo = () => {
        return customizations?.logo || '/letsburstlogo.png';
    }
    const getLogoUrl = () => {
        return customizations?.logoUrl || null;
    }

    return {
        questions,
        pageNumber,
        next,
        nextContact,
        back, activeQuestion,
        questionCnt: questions.filter(a => a.isQuestion).length,
        responses,
        setResponses,
        saveResponses,
        comment,
        setComment,
        last,
        isSaving,
        customizations,
        getLogo,
        getQuestionText
    }
}

const postResponses = async (questions, responses, comment) => {
    const ip_response = await axios.get('https://geolocation-db.com/json/')
    console.log('postResponses: ip', ip_response.data)

    let ratings = {};
    questions.forEach(q => {
        if (q.isQuestion && q.idExperience) {
            q.options.forEach(r => ratings[r.id] = r);
        }
    })

    let experiences = [];
    Object.values(responses).forEach(value => {
        value.forEach(ratingId => {
            let rating = ratings[Number(ratingId)];
            console.log('rating', rating);
            if (rating) {
                experiences.push(
                    {
                        "id": 0,
                        "idExperience": rating.idExperience,
                        "idRatings": rating.id,
                        "ratingsValue": rating.value
                    }
                )
            }
        })
    });

    let data =
    {
        "id": 0,
        "idCustomer": defaultApiValues.idCustomer,
        "idProduct": defaultApiValues.idProduct,
        "age": "",
        "gender": "",
        "ip": "remoteip",
        "browser": browserName,
        "platform": osName,
        "images": "",
        "url": window.location.href,
        "registrationDate": dayjs().format(),
        "categories": [
            {
                "id": 0,
                "idCategory": defaultApiValues.idCategory,
                "like": "",
                "disLike": "",
                "comment": comment,
                "experiences": experiences
            }
        ]
    };
    let config = {
        method: 'post',
        url: 'https://burst-main-service.letsburst.com/api/Response',
        headers: {
            'Content-Type': 'application/json'
        },
        data: JSON.stringify(data)
    };
    let result = await axios(config);
    console.log(result);

}

const PostData = async (url, data, authToken) => {
    let headers = {
        'Content-Type': 'application/json'
    };
    if (authToken) {
        headers.Session = authToken
    }
    let config = {
        method: 'post',
        url: url,
        headers: headers,
        data: JSON.stringify(data)
    };
    console.log('PostData', config);
    let result = await axios(config);
    console.log('PostData: data', result?.data);
    return result;
}
