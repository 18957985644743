import { useQuestionContext } from "../../context/QuestionsContext";
import { ImageComponent } from "../Image";
import { formatStyle } from "../StyleUtil";
import { useState } from "react";

export const QuestionText = props => {
    const { comment, setComment, getLogo } = useQuestionContext();
    const { next, isSaving, getQuestionText } = useQuestionContext();
    const [warning, setWarning] = useState(null);

    return (
        <>
            <div className="questionContainer">
                <ImageComponent src={getLogo()} style={{ height: 50, marginTop: 20 }} isVisible={props.question.showLogo} />
                <h1 style={formatStyle(props?.question?.textStyle)}>{getQuestionText(props.question.text)}</h1>
                <textarea
                    className="textInput"
                    type='text'
                    placeholder={getQuestionText(props.question.placeholder)}
                    value={comment}
                    style={{ marginBottom: 20 }}
                    onChange={(event) => setComment(event.target.value)} />
                {warning}
                <div className="button-float clickable" onClick={() => {
                    if (!props.question.isRequired || (comment && comment.length > 0)) {
                        next(props.question, null, comment);
                    } else {
                        setWarning(<div className="info" style={{ color: 'red' }}>Please enter a response</div>)
                    }
                }}>
                    <p>{props.question?.buttonText || 'Next'}</p>
                </div>
                <div className="powered-by clickable" onClick={() => window.location.href = "https://letsburst.com/"}>
                    <p>Powered By</p>
                    <img src={'/letsburstlogo.png'} alt="burst_logo" />
                </div>
            </div>
        </>
    )
}
