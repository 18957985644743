import { useState } from "react";
import { useQuestionContext } from "../../context/QuestionsContext";
import { GetIcon, ImageComponent } from "../Image";
import { formatStyle } from "../StyleUtil";
import parse from "html-react-parser";

export const QuestionRadio = props => {
    const [response, setResponse] = useState(0);
    const { next, getLogo, customizations } = useQuestionContext();
    const [warning, setWarning] = useState(null);

    console.log('QuestionRadio', props.question);
    return (
        <>
            <div className="questionContainer">
                <ImageComponent
                    src={getLogo()}
                    link={customizations?.logoUrl}
                    style={{ height: 50, marginTop: 20 }}
                    isVisible={props.question.showLogo}
                />
                <h1 style={formatStyle(props?.question?.textStyle)}>{props.question.text}</h1>
                <div style={{ paddingLeft: 10, paddingRight: 10 }}>
                    <p style={formatStyle(props?.question?.placeholderStyle)}>{parse(props.question?.placeholder || '')}</p>
                </div>
                {props.question.options.map(option => {
                    return (
                        <div key={`options_${Math.random()}`} className="optionsBox clickable" onClick={() => {
                            setResponse(option.id);
                        }}>

                            <div style={{ display: 'flex', height: 36, alignItems: 'center', flexDirection: 'row' }}>
                                {GetIcon(option)}
                                <p>{option.text}</p>
                            </div>


                            <input
                                type='radio'
                                className="optionsBoxInput clickable"
                                checked={option.id === response}
                                onChange={() => {

                                }}
                            />
                        </div>
                    )
                })}
                {warning}
                <div className="button-float clickable" onClick={() => {
                    if (response > 0) {
                        next(props.question, [response])
                    }
                    else {
                        setWarning(<div className="info" style={{ color: 'red' }}>Please select one option</div>)
                    }
                }}>
                    <p>{props.question?.buttonText || 'Next'}</p>
                </div>
                <div className="powered-by-float clickable" onClick={() => window.location.href = "https://letsburst.com/"}>
                    <p>Powered By</p>
                    <img src={'/letsburstlogo.png'} alt="burst_logo" />
                </div>
            </div>
        </>

    )
}
