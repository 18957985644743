export const formatStyle = (styleObj) => {
    if (!styleObj) {
        return {}; // default style or an empty object
    }

    return {
        color: styleObj.color || null, // default color to black if it's not provided
        fontStyle: styleObj.italic ? 'italic' : null,
        fontSize: styleObj.fontSize || null, // default font size to 16 if it's not provided
        textDecoration: styleObj.underline ? 'underline' : null,
        fontWeight: styleObj.fontWeight ? 'bold' : null,
    };
};
