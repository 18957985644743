import { useQuestionContext } from "../../context/QuestionsContext";
import { ImageComponent } from "../Image";
import parse from "html-react-parser";
import { formatStyle } from "../StyleUtil";

// to be default
export const WelcomeDefault = props => {
    const { next, getLogo } = useQuestionContext();
    return (
        <div id="welcome">
            <div className="container">
                <div className="floating-card fixed-bottom">
                    <div className="container-text">
                        <div className="row">
                            <ImageComponent src={getLogo()} style={{ maxHeight: 200, maxWidth: 300 }} />
                        </div>
                        <div className="row">
                            <div className="col">
                                <h1 style={formatStyle(props?.question?.textStyle)}>{parse(props?.question?.text || '')}</h1>
                            </div>
                        </div>
                        <div className="row mt-3">
                            <div className="col">
                                <h2 style={formatStyle(props?.question?.placeholderStyle)}>{parse(props.question?.placeholder || '')}</h2>
                            </div>
                        </div>
                        <div className="button-float clickable"
                            onClick={() => {
                                next();
                            }}>
                            <p>{props.question?.buttonText || 'Next'}</p>
                        </div>
                        <div className="powered-by-float clickable" onClick={() => window.location.href = "https://letsburst.com/"}>
                            <p>Powered By</p>
                            <img src={'/letsburstlogo.png'} alt="burst_logo" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
