import { useState } from "react";
import { useQuestionContext } from "../../context/QuestionsContext";
import { formatStyle } from "../StyleUtil";
import { GetIcon, ImageComponent } from "../Image";

const buildResponseIndex = (options => {
    let a = {};
    options.forEach(o => a[o.id] = false);
    return a;
})

export const QuestionCheckbox = props => {
    const [responses, setResponses] = useState(buildResponseIndex(props.question.options));
    const { next, getLogo } = useQuestionContext();
    const [warning, setWarning] = useState(null);


    console.log('QuestionSelect', responses);
    return (
        <>
            <div className="questionContainer">
                <ImageComponent src={getLogo()} style={{ height: 50, marginTop: 20 }} isVisible={props.question.showLogo} />
                <h1 style={formatStyle(props?.question?.textStyle)}>{props.question.text}</h1>
                <p className="info">Please select all that apply</p>
                {props.question.options.map(option => {
                    return (
                        <div key={`options_${Math.random()}`} className="optionsBox clickable" onClick={() => {
                            setResponses({ ...responses, [option.id]: !responses[option.id] })
                        }}>
                            <p>{GetIcon(option)}{option.text}</p>
                            <input
                                type='checkbox'
                                className="optionsBoxInput clickable"
                                checked={responses[option.id]}
                                onChange={() => {

                                }}
                            />
                        </div>
                    )
                })}
                {warning}
                <div className="button-float clickable"
                    onClick={() => {
                        let response = [];
                        Object.entries(responses).forEach(entry => {
                            const [key, value] = entry;
                            if (value) response.push(key)
                        });
                        if (!props.question.isRequired || response.length > 0) {
                            next(props.question, response)
                        }
                        else {
                            setWarning(<div className="info" style={{ color: 'red' }}>Please select at least one option</div>)
                        }
                    }}>
                    <p>Next</p>
                </div>
                <div className="powered-by-float clickable" onClick={() => window.location.href = "https://letsburst.com/"}>
                    <p>Powered By</p>
                    <img src={'/letsburstlogo.png'} alt="burst_logo" />
                </div>
            </div>
            <div className="footer">
            </div>
        </>

    )
}
