import {useQuestionContext} from "../context/QuestionsContext";

export const Header = props => {
    const {questionCnt, back, activeQuestion, getLogo} = useQuestionContext()
    let progressBar = null;
    console.log('progress', {questionCnt, props});

    if(questionCnt && props.questionNumber) {
        let percent = props.questionNumber/questionCnt * 100;
        if(props.progressMessage) {
            progressBar = (
                <div className="progress-status">
                    <p>{props.progressMessage}</p>
                    <div className="progress-bar-container" >
                        <div className="progress-bar-bar" style={{width: `100%`}}/>
                    </div>
                </div>
            );
        } else {
            progressBar = (
                <div className="progress-status">
                    <p>{props.questionNumber}/{questionCnt}</p>
                    <div className="progress-bar-container" >
                        <div className="progress-bar-bar" style={{width: `${percent}%`}}/>
                    </div>
                </div>
            );
        }
    }
    let BACK = props.progressMessage || activeQuestion.sequence === 0
    ? null
    : <div className="header-back clickable" onClick={()=>back()}>
            <svg width="9" height="16" style={{marginRight: 10}} viewBox="0 0 9 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0.292893 7.29289C-0.097631 7.68342 -0.097631 8.31658 0.292893 8.70711L6.65685 15.0711C7.04738 15.4616 7.68054 15.4616 8.07107 15.0711C8.46159 14.6805 8.46159 14.0474 8.07107 13.6569L2.41421 8L8.07107 2.34315C8.46159 1.95262 8.46159 1.31946 8.07107 0.928932C7.68054 0.538408 7.04738 0.538408 6.65685 0.928932L0.292893 7.29289ZM2 7H1L1 9H2L2 7Z" fill="#0A2240"/>
            </svg>
            Back
    </div>
    return (
        <>
            {progressBar}
            {BACK}
        </>
    )
}
