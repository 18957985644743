import {useState} from "react";
import {useQuestionContext} from "../../context/QuestionsContext";
import {GetIcon} from "../Image";
import {formatStyle} from "../StyleUtil";

const buildResponseIndex = (options=>{
    let a = {};
    options.forEach(o=>a[o.id]='');
    return a;
})

export const QuestionContactForm = props => {
    const [responses, setResponses] = useState(buildResponseIndex(props.question.options));
    const {nextContact} = useQuestionContext();

    console.log('QuestionSelect', responses);
    return (
        <>
            <div className="questionContainer">
                <h1 style={formatStyle(props?.question?.textStyle)}>{props.question.text}</h1>
                <h2 style={formatStyle(props?.question?.placeholderStyle)}>{props.question.placeholder}</h2>
                {props.question.options.map(option => {
                    return (
                        <div key={`options_${option.id}`} className="optionsBox">
                            <p>{GetIcon(option)}{option.text}</p>
                            <input
                                type='text'
                                className="optionsBoxText"
                                key={`text_${option.id}`}
                                value={responses[option.id]}
                                onChange={(e)=>{
                                    console.log('onChange', {...responses, [option.id]: e.target.value})
                                    setResponses(prevState => {
                                        return {...prevState, [option.id]: e.target.value}
                                    })
                                }}
                            />
                        </div>
                    )
                })}
                <div className="button-float clickable"
                     onClick={() => {
                         nextContact(props.question, responses);
                     }}>
                    <p>{props.question?.buttonText || 'next'}</p>
                </div>
                <div className="powered-by-float clickable" onClick={()=>window.location.href="https://letsburst.com/"}>
                    <p>Powered By</p>
                    <img src={'/letsburstlogo.png'} alt="burst_logo"/>
                </div>
            </div>
        </>

    )
}
